import React from "react"

export default function Layout({children}) {
    return (
        <div className="h-full w-full">
            <div>
                {children}
            </div>
        </div>
    )
}